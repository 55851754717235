<template>
    <div :class="{ newtab_page: isNewtab }">
        <div id="header_root" class="p-d-print-none" v-if="isNewtab">
            <div id="header-outer-wrapper" class="p-py-2 p-grid p-d-print-none">
                <div
                    id="header-left"
                    class="p-col-6 p-d-none p-d-md-block p-px-4"
                >
                    <a href="/">
                        <div class="headerLogoWrapper">
                            <img
                                id="headerLogo"
                                src="/cooking/assets/images/freshy_recipes_logo.svg"
                                alt="cooking.freshy.com"
                            />
                        </div>
                    </a>
                </div>
                <div id="header-right" class="p-col-6 p-grid">
                    <div class="headerTextVerticalAligned p-col-12">
                        <AuthMenu />
                    </div>
                </div>
            </div>
            <div id="header-center" class="header-section p-col-8 p-offset-2">
                <AutoComplete
                    id="header-search-input"
                    inputClass="headerSearchInput p-px-4 p-py-3"
                    @keydown.enter="onSearchSubmit(query)"
                    v-model="query"
                    field="text"
                    placeholder="Search For Recipes"
                    @complete="getAutocompleteSuggestions"
                    :suggestions="autoCompleteSuggestions"
                    @item-select="onAutocompleteItemSelect"
                ></AutoComplete>

                <Button
                    label="Search"
                    id="searchBtn"
                    class="p-button p-component"
                    @click="onSearchSubmit(query)"
                />
            </div>
        </div>
        <div id="header_root" class="home p-d-print-none" v-else>
            <div
                id="header-outer-wrapper"
                class="p-d-print-none p-grid"
                :class="{ 'p-shadow-2': !home, minhead: !home }"
            >
                <div
                    id="header-left"
                    class="header-section p-d-none p-d-none p-d-md-block p-lg-3 p-md-4"
                    :class="{ minLogo: !home }"
                >
                    <a href="/">
                        <div class="headerLogoWrapper">
                            <img
                                id="headerLogo"
                                src="/cooking/assets/images/freshy_recipes_logo.svg"
                                alt="cooking.freshy.com"
                            />
                        </div>
                    </a>
                </div>
                <div id="header-center" class="header-section p-lg-5 p-md-7">
                    <div class="headerTextVerticalAligned">
                        <AutoComplete
                            id="header-search-input"
                            inputClass="headerSearchInput"
                            @keydown.enter="onSearchSubmit(query)"
                            v-model="query"
                            field="text"
                            placeholder="Search For Recipes"
                            @complete="getAutocompleteSuggestions"
                            :suggestions="autoCompleteSuggestions"
                            @item-select="onAutocompleteItemSelect"
                        ></AutoComplete>
                    </div>
                </div>
                <div id="header-right" class="header-section p-lg-4 p-md-1">
                    <div class="headerTextVerticalAligned">
                        <AuthMenu />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import { recipesAutocompleteSearch } from "../../fetchFunctions";
import AuthMenu from "../auth/authMenu/AuthMenu";
import newtabMixin from "../componentMixins/newtabMixin";
import { isStaging } from "../../utility";

export default {
    name: "Header",
    mixins: [newtabMixin],
    props: ["triggerOnEmit", "home"],
    components: {
        AutoComplete,
        AuthMenu,
        Button,
    },
    data() {
        return {
            query: null,
            autoCompleteSuggestions: null,
            isStaging: isStaging(),
        };
    },
    mounted() {
        const qs = new URLSearchParams(window.location.search);
        if (qs && qs.get("q")) this.query = qs.get("q");
    },
    methods: {
        onSearchSubmit() {
            if (this.query) {
                const baseUrl = this.isStaging
                    ? "https://staging.cooking.freshy.com/search"
                    : "https://cooking.freshy.com/search";

                if (this.triggerOnEmit) {
                    // If trigger on emit
                    this.$emit("onSearch", this.query);
                } else {
                    // Redirect to the appropriate search URL
                    window.location.href = `${baseUrl}?q=${encodeURIComponent(
                        this.query
                    )}`;
                }
            }
        },
        async getAutocompleteSuggestions(ev) {
            const term = ev.query;
            const res = await recipesAutocompleteSearch(term);
            this.autoCompleteSuggestions = res;
        },
        onAutocompleteItemSelect(ev) {
            const { value } = ev;
            const { text } = value;
            this.query = text;
            if (text) this.$emit("onSearch", text);
            else {
                // No Handler Sumbitted
                this.$router.push({
                    name: "Search",
                    query: {
                        q: text,
                    },
                });
            }
        },
    },
};
</script>

<style scoped src="./header.css"></style>

<style scoped lang="scss">
.newtab_page {
    @import "./header_newtab.scss";
}
</style>
