function getMediaBreakpoint(customBreakpoints = null) {
    const breakpoints = {
        sm: "576px",
        md: "768px",
        lg: "992px",
        xl: "1200px",
    };

    if (customBreakpoints) {
        for (const i in customBreakpoints) {
            breakpoints[i] = customBreakpoints[i];
        }
    }

    const xl = window.matchMedia(`(min-width: ${breakpoints.xl})`);
    const lg = window.matchMedia(`(min-width: ${breakpoints.lg})`);
    const md = window.matchMedia(`(min-width: ${breakpoints.md})`);
    const sm = window.matchMedia(`(min-width: ${breakpoints.sm})`);

    if (xl.matches) return "xl";
    if (lg.matches) return "lg";
    if (md.matches) return "md";
    if (sm.matches) return "sm";
    return "xs";
}

function getIsoDurationInSeconds(isoDuration) {
    const duration = isoDurationParser(isoDuration);
    if (duration) {
        const hourInSec = 60 * 60 * duration.hour;
        const minInSec = 60 * duration.min;
        return hourInSec + minInSec + duration.sec;
    }
    return null;
}

function isoDurationParser(isoDuration) {
    const getNumberComponent = function(str) {
        // const validCheck = /[0-9]*[A-Z]$/;
        const numberOnly = /[0-9]*/;
        if (numberOnly.test(str)) {
            const numStr = numberOnly.exec(str)[0];
            const output = parseInt(numStr);
            return output;
        }
        return null;
    };

    const validIsoDuration = /^PT([0-9]*[H,M,S])+$/;

    const hourCheck = /[0-9]*H/;
    const minCheck = /[0-9]*M/;
    const secCheck = /[0-9]*S/;

    if (validIsoDuration.test(isoDuration)) {
        const output = { hour: null, min: null, sec: null };

        if (hourCheck.test(isoDuration))
            output.hour = getNumberComponent(hourCheck.exec(isoDuration)[0]);
        if (minCheck.test(isoDuration))
            output.min = getNumberComponent(minCheck.exec(isoDuration)[0]);
        if (secCheck.test(isoDuration))
            output.sec = getNumberComponent(secCheck.exec(isoDuration)[0]);

        return output;
    }
    return null;
}

function getFormattedIsoDuration(isoDuration, prefixStr = null) {
    const duration = isoDurationParser(isoDuration);
    if (duration) {
        let output = "";
        let hasLength = false;
        if (prefixStr) output += prefixStr;
        if (duration.hour) {
            output += `${duration.hour}h`;
            hasLength = true;
        }
        if (duration.min) {
            output += `${duration.min}m`;
            hasLength = true;
        }
        if (duration.sec) {
            hasLength = true;
            output += `${duration.sec}s`;
        }
        if (hasLength) return output;
        return "";
    }
    return "";
}

function getShortMonthName(num) {
    const months = [
        "Jan",
        "Feb",
        "Ma",
        "Ap",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];
    if (num >= 0 && num <= 11) return months[num];
    return null;
}

function getShortDayName(num) {
    const days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    if (num >= 0 && num <= 6) return days[num];
    return null;
}

function getDateSuffix(date) {
    if (date % 10 == 1 && date % 100 != 11) return "st";
    if (date % 10 == 2 && date % 100 != 12) return "nd";
    if (date % 10 == 3 && date % 100 != 13) return "rd";
    return "th";
}

const documentReady = new Promise(resolve => {
    if (document.readyState === "complete") {
        resolve(true);
        return;
    }
    document.addEventListener("DOMContentLoaded", () => {
        resolve(true);
    });
});

function titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
}

function getUserConsentDate(today = false) {
    const date = () => {
        const now = new Date(Date.now());
        let day = now.getDate();
        if (day < 10) day = `0${day}`;
        let month = now.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let year = now.getFullYear() % 100; // get last two digits
        if (year < 10) year = `0${year}`;

        return `${month}${day}${year}`;
    };
    if (today) return date();

    let userInstallDate = localStorage.getItem("cookingfreshy_UserConsentDate");
    if (userInstallDate) return userInstallDate;

    userInstallDate = date();
    localStorage.setItem("cookingfreshy_UserConsentDate", userInstallDate);
    return userInstallDate;
}

function isStaging() {
    return window.location.hostname.includes("staging");
}

function getTypeTag(typeTag, setTagInUrl = false) {
    if (typeTag) {
        const basicTypeTagRegex = /Y.*_F.*/;
        if (basicTypeTagRegex.test(typeTag)) {
            // check for date attached
            const typeParts = typeTag.split("_");
            if (typeParts.length < 4 || typeParts[3] == "") {
                typeTag += `${
                    typeParts[3] == "" ? "" : "_"
                }${getUserConsentDate(true)}`;
            }
        }
        if (setTagInUrl) {
            const url = new URL(window.location.href);
            url.searchParams.set("type", typeTag);
            window.history.replaceState({}, null, url.toString());
        }
    }
    return typeTag;
}

function getPlatformClass() {
    return navigator.platform == "Win32" ? "win" : "mac";
}

function insertInArrAtInterval(
    arr,
    newItems,
    interval = 5,
    maxReplaceCount = null
) {
    if (arr && newItems) {
        const newArr = [...arr];
        if (newItems[0]) newArr.unshift(newItems[0]);

        if (arr.length > interval) {
            const intervalCount = maxReplaceCount
                ? maxReplaceCount - 1
                : Math.floor(arr.length / interval);
            for (let i = 1; i <= intervalCount; i++) {
                if (
                    (maxReplaceCount || arr.length >= i * interval) &&
                    newItems.length > i
                )
                    newArr.splice(i * interval, 0, newItems[i]);
            }
        }

        return newArr;
    }
    return null;
}

function shuffleArr(array) {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
    return arr;
}

function getImageWrapper(url, options = {}) {
    if (!url) return "";
    const wrapperUrl = "https://recipe-images.cooking.freshy.com";
    // Only wrap URLs from specific domains
    if (
        typeof url === "string" &&
        (url.startsWith("https://storage.googleapis.com/skai-imagegen") ||
            url.startsWith("https://images.trai.services"))
    ) {
        if (url.startsWith("https://storage.googleapis.com/skai-imagegen")) {
            url = url.replace(
                "https://storage.googleapis.com/skai-imagegen",
                ""
            );
        } else if (url.startsWith("https://images.trai.services")) {
            url = url.replace("https://images.trai.services", "");
        }
        const params = new URLSearchParams({
            class: options.class || "small",
            optimizer: options.optimizer || "image",
            ...options,
        });

        return `${wrapperUrl}${url}?${params.toString()}`;
    }

    return url;
}

export {
    isStaging,
    getMediaBreakpoint,
    getFormattedIsoDuration,
    getIsoDurationInSeconds,
    isoDurationParser,
    getShortDayName,
    getShortMonthName,
    getDateSuffix,
    titleCase,
    documentReady,
    getTypeTag,
    getPlatformClass,
    insertInArrAtInterval,
    shuffleArr,
    getImageWrapper,
};
