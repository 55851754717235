<template>
    <div id="footer_root" class="p-d-print-none">
        <div
            id="home-footer"
            class="footer"
            :class="isNewtab ? 'white-bg' : ''"
        >
            <img
                v-if="!isNewtab"
                src="/cooking/assets/images/footerLogo.png"
                alt="Freshy Recipes"
            />
            <img
                v-else
                src="/cooking/assets/images/freshy_recipes_logo.svg"
                alt="Freshy Recipes"
            />
            <span :class="isNewtab ? 'newtab' : ''">
                All trademarks are property of their respective owners.
                Tightrope Interactive
            </span>

            <a
                :href="
                    isStaging
                        ? 'https://staging.cooking.freshy.com/terms-of-service'
                        : 'https://cooking.freshy.com/terms-of-service'
                "
                >Terms of Service</a
            >

            <a
                :href="
                    isStaging
                        ? 'https://staging.cooking.freshy.com/privacy-policy'
                        : 'https://cooking.freshy.com/privacy-policy'
                "
                >Privacy Policy</a
            >

            <a
                :href="
                    isStaging
                        ? 'https://staging.cooking.freshy.com/privacy-policy#cali-consumer'
                        : 'https://cooking.freshy.com/privacy-policy#cali-consumer'
                "
                >Do Not Sell My Personal Information</a
            >

            <a
                :href="
                    isStaging
                        ? 'https://staging.cooking.freshy.com/uninstall'
                        : 'https://cooking.freshy.com/uninstall'
                "
                >Uninstall</a
            >

            <a
                :href="
                    isStaging
                        ? 'https://staging.cooking.freshy.com/contact-us'
                        : 'https://cooking.freshy.com/contact-us'
                "
                >Contact</a
            >
        </div>
    </div>
</template>

<script>
import newtabMixin from "../componentMixins/newtabMixin";
import { isStaging } from "../../utility";
export default {
    name: "Footer",
    mixins: [newtabMixin],
    data() {
        return {
            isStaging: isStaging(),
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped src="./footer.css"></style>
